<template>
  <el-card class="iot-person public-page-style iot-search-header">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.username"
            placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.fullName"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item>
        <el-input
            size="small"
            clearable
            v-model="getForm.mobile"
            placeholder="请输入手机号"
          ></el-input>
          </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="checkTable()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          plain
          size="mini"
          class="iot-account-create"
          v-if="$store.state.permissionArr.indexOf('user:add') > -1"
          @click="addAccount()"
          >新增</el-button
        >
      </el-form>
    </div>
    <div class="tab" style="height:690px;overflow-y: scroll;">

    
    <el-table
      :data="tableData"
      stripe
      border=""
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <!-- <el-table-column prop="userId" label="用户编号"></el-table-column> -->
      
      <el-table-column prop="username" label="用户名"></el-table-column>
      <el-table-column prop="fullName" label="姓名"></el-table-column>
      <el-table-column prop="mobile" width="115" label="手机号"></el-table-column>
      <el-table-column label="激活状态" width="80">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.userState == 0">已激活</el-tag>
          <el-tag type="info" v-else-if="scope.row.userState == 1"
            >未激活</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="roleName" label="角色"></el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>
      <el-table-column prop="modifyTime" label="更新时间"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="editAccount(scope.row.userId)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click="del(scope.row.userId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      
    </el-table>
    </div>
    <div style="display:flex;justify-content: flex-end;">
      <el-pagination
        background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-sizes="[10, 20, 30, 40]"
        :total="total"
        :page-size="getForm.size"
        style=""
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
      >
      </el-pagination>
    </div>
    
    <el-dialog
    top="8%"
      :show-close="false"
      :visible.sync="accountDialogVisinbile"
      :before-close="handleClose"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
      width="30%"
      class="iot-account"
    >
      <div slot="title" style="">
        <img
          style="width: 25px; vertical-align: middle; margin-top: -5px"
          src="../assets/pencil.png"
        />
        <div style="margin-left: 7px; padding-top: 15px; display: inline-block">
          {{ formTitle }}
        </div>
      </div>
      <el-form
        :model="accountForm"
        ref="userRef"
        :rules="rules"
        label-width="100px"
        style="width: calc(100% - 60px)"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            v-model="accountForm.username"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="fullName">
          <el-input
            v-model="accountForm.fullName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="accountForm.mobile" autocomplete="off">
            <el-button :disabled="isSend" slot="append" @click="activation()">{{
              smsCode
            }}</el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode" v-if="isActivation">
          <el-input v-model="accountForm.smsCode" autocomplete="off">
            <el-button
              slot="append"
              @click="
                () => {
                  isActivation = false;
                  smsCode = '验证码';
                  isSend = false;
                }
              "
              >暂不激活</el-button
            >
          </el-input>
        </el-form-item>
        <el-form-item label="选择角色" prop="ruleId">
          <el-select
            v-model="accountForm.roleId"
            placeholder="请选择"
            :multiple-limit="1"
          >
            <el-option
              v-for="item in roleArr"
              :key="item.roleId"
              :label="item.roleName"
              :value="item.roleId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer"> 
        <el-button type="primary" plain size="mini" @click="submitForm()"
          >确 定</el-button
        >
        <el-button type="info" plain size="mini" @click="handleClose()"
          >取 消</el-button
        >
       
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
import reg from "../util/regular";
let that;
export default {
  name: "Account",
  data() {
    return {
      time:180,
      theme: { TABLE_HEADER_COLOR },
      accountDialogVisinbile: false,
      tableData: [],
      getForm: {
        username:"",
        fullName:"",
        mobile: "",
        size: 10,
        current: 1,
      },
      total: 0,
      accountForm: {
        userId: "",
        username: "",
        password: "",
        mobile: "",
        roleId: "",
        smsCode: "",
        fullName: "",
      },
      permissionForm: {},
      isCreate: true,
      formTitle: "",
      roleArr: [],
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            pattern: reg.accountR,
            message: "限6-18个字符，支持英文、数字、减号或下划线",
          },
        ],
        fullName: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min:1,max:15, message: "姓名长度为1-15位", trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: reg.mobileR,
            message: "手机号格式有误",
          },
        ],
      },
      smsCode: "验证码",
      isActivation: false,
      isSend: false,
      int: null,
    };
  },
  methods: {
    //分页方法
     handleSizeChange(val) {
        that.getForm.size=val;
        that.getList();
      },
      handleCurrentChange(val) {
        that.getForm.current=val;
        that.getList();
      },

    submitForm: function () {
      that.$refs["userRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.account.createAccount(that.accountForm)
            : that.$api.account.editAccount(that.accountForm);
          _api.then((response) => {

            
            if (response.code==200) {
              that.$refs.userRef.resetFields();
            that.accountDialogVisinbile = false;
            that.isActivation = false;
            that.smsCode = "验证码";
            that.isSend = false;
            that.isCreate = true;
            that.$refs.userRef.resetFields();
            clearInterval(that.int);
            that.getList();
               that.$message({
              type: "success",
              message: response.msg,
            });
            }
           
          });
        }
      });
    },
    checkTable: function () {
      that.$set(that.getForm, "current", 1);
      that.getList();
    },
    getList: function () {
      that.$api.account.accountList(that.getForm).then((response) => {
        that.tableData = response.data.records;
        that.total = response.data.total;
      });
    },
    addAccount: function () {
      that.$api.account.getRoleNoPage().then((response) => {
        if (response.code == 200) {
          that.roleArr = response.data;
          that.formTitle = "新增用户";
          that.accountDialogVisinbile = true;
          that.isCreate = true;
          that.accountForm = {};
        }
      });
    },
    handleClose: function () {
      that.accountDialogVisinbile = false;
      that.isActivation = false;
      that.smsCode = "验证码";
      that.isSend = false;
      clearInterval(that.int);
      that.$refs.userRef.resetFields();
    },
    editAccount: function (accountId) {
      that.$api.account.getRoleNoPage().then((response) => {
        if (response.code == 200) {
          that.roleArr = response.data;
          that.$api.account.getAccount(accountId).then((response) => {
            if (response.code == 200) {
              that.accountForm = response.data;
              that.formTitle = "修改用户";
              that.accountDialogVisinbile = true;
              that.isCreate = false;
            }
          });
        }
      });
    },
    del: function (accountId) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.account.delAccount(accountId).then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getList();
            }
          });
        })
        .catch(() => {});
    },
    activation: function () {
      if (reg.mobileR.test(that.accountForm.mobile)) {
        that.$api.auth
          .mobileGetCode(that.accountForm.mobile)
          .then((response) => {
            if (response.code == 200) {
              that.$message({
                type: "success",
                message: "验证码已发送",
              });
              that.isActivation = true;
              that.isSend = true;
              let count = 120;
              that.int = setInterval(() => {
                if (count > 0) {
                  that.smsCode = count-- + "秒";
                } else {
                  clearInterval(that.int);
                  that.smsCode = "验证码";
                  that.isSend = false;
                }
              }, 1000);
            }
          });
      } else {
        that.$message({
          type: "warning",
          message: "手机号码有误",
        });
      }
    },
  },
  beforeCreate: function () {
    that = this;
  },
  mounted: function () {
    that.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.iot-account-create {
  float: right;
  margin: 10px;
  
}
.iot-account-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-person >>> .el-select {
  width: 100%;
}
.iot-account >>> .el-dialog {
  height: 678px;
  
}
::v-deep .tab::-webkit-scrollbar{
  display: none;
}
</style>
